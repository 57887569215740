<template>
  <div class="input_calendar mid">
    <input
      type="text"
      :placeholder="$t('comp.010')"
      v-model="formattedDate"
      ref="dp"
      class="datepicker"
      :id="id"
      :readonly="readonly"
      :disabled="disabled"
      :stopClickFunc="stopClickFunc"
      autocomplete="off"
      maxlength="10"
      @click="showPicker"
      @change="changeDt"
      @keydown="keyinput"
    />
    <button v-if="stopClickFunc === 'Y'" type="button" class="tbl_icon calendar" @mousedown.stop.prevent @click.prevent="togglePicker">{{ $t('comp.014') }}</button>
    <button v-else type="button" class="tbl_icon calendar" @mousedown.stop.prevent @click.stop.prevent="togglePicker">{{ $t('comp.014') }}</button>
  </div>
</template>

<script>
export default {
  name: 'DatePicker',

  props: {
    value: { type: String, default: '', required: true },
    readonly: { type: Boolean, default: false, required: false },
    disabled: { type: Boolean, default: false, required: false },
    id: { type: String, default: '', required: false },
    stopClickFunc: { type: String, default: '', required: false }
  },

  data () {
    return {
      unformattedDate: this.value,
      format: 'YYYY.MM.DD',
      show: false
    }
  },

  computed: {
    formattedDate: {
      set (v) {
        this.unformattedDate = v.replace(/[^\d]/g, '')
      },

      get () {
        return this.unformattedDate.replace(/(\d{4})(\d{1,2}(?=\d{2})|\d{1,2}(?=\d{1}))(\d{1,2}).*/, '$1.$2.$3')
      }
    }
  },

  watch: {
    value () {
      this.unformattedDate = this.value
    },
    readonly () {
      if (!this.readonly) {
        this.dpMount()
      } else {
        this.dpDestroy()
      }
    }
  },

  mounted () {
    if (!this.readonly) {
      this.dpMount()
    } else {
      this.dpDestroy()
    }
  },

  methods: {
    keyinput (e) {
      this.hidePicker()
    },

    showPicker (e) {
      $(this.$refs.dp).datepicker('show')
      this.show = true
    },

    hidePicker (e) {
      $(this.$refs.dp).datepicker('hide')
      this.show = false
    },

    togglePicker (e) {
      if (this.$props.disabled) {
        return
      }
      if (this.show) {
        this.hidePicker()
      } else {
        this.showPicker()
      }
    },

    emitChange () {
      console.log('@@@@ emitChange')
      this.$emit('input', this.unformattedDate)
    },

    changeDt (e) {
      const t = e.target
      const m = t.value.match(/(\d+)\.(\d+)\.(\d+)/)
      t.value = t.oldValue ?? ''

      if (m) {
        const mo = moment({
          year: parseInt(m[1]),
          month: parseInt(m[2]) - 1,
          day: parseInt(m[3])
        })

        if (mo.isValid()) {
          t.value = mo.format(this.format)
          t.oldValue = t.value
        }
      }

      this.formattedDate = t.value
      this.emitChange()
    },

    dpDestroy () {
      $(this.$refs.dp).datepicker('destroy')
    },

    dpMount () {
      $(this.$refs.dp).datepicker({
        dateFormat: 'yy.mm.dd',
        closeText: '닫기',
        prevText: '이전달',
        nextText: '다음달',
        currentText: '오늘',
        monthNames: [
          `${this.$t('comp.1_month')}`,
          `${this.$t('comp.2_month')}`,
          `${this.$t('comp.3_month')}`,
          `${this.$t('comp.4_month')}`,
          `${this.$t('comp.5_month')}`,
          `${this.$t('comp.6_month')}`,
          `${this.$t('comp.7_month')}`,
          `${this.$t('comp.8_month')}`,
          `${this.$t('comp.9_month')}`,
          `${this.$t('comp.10_month')}`,
          `${this.$t('comp.11_month')}`,
          `${this.$t('comp.12_month')}`
        ],
        monthNamesShort: [
          `${this.$t('comp.1_month')}`,
          `${this.$t('comp.2_month')}`,
          `${this.$t('comp.3_month')}`,
          `${this.$t('comp.4_month')}`,
          `${this.$t('comp.5_month')}`,
          `${this.$t('comp.6_month')}`,
          `${this.$t('comp.7_month')}`,
          `${this.$t('comp.8_month')}`,
          `${this.$t('comp.9_month')}`,
          `${this.$t('comp.10_month')}`,
          `${this.$t('comp.11_month')}`,
          `${this.$t('comp.12_month')}`
        ],
        dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
        dayNamesShort: [
          this.$t('comp.002'),
          this.$t('comp.003'),
          this.$t('comp.004'),
          this.$t('comp.005'),
          this.$t('comp.006'),
          this.$t('comp.007'),
          this.$t('comp.008')
        ],
        dayNamesMin: [
          this.$t('comp.002'),
          this.$t('comp.003'),
          this.$t('comp.004'),
          this.$t('comp.005'),
          this.$t('comp.006'),
          this.$t('comp.007'),
          this.$t('comp.008')
        ],
        weekHeader: '주',
        onSelect: (v) => {
          this.unformattedDate = v.replaceAll('.', '')
          this.$emit('input', this.unformattedDate)
        },
        onClose: () => {
          this.show = false
        },
        beforeShow: () => {
          this.show = true
        }
      })
      // JamSin 번역 막기 $('.ui-datepicker').addClass('notranslate')
    }
  }
}
</script>

<style>
.ui-datepicker .ui-state-default {
  border: 1px solid #ffffff !important;
  background: #ffffff !important;
  font-weight: normal;
  color: #454545;
  text-align: center !important;
}

.ui-datepicker .ui-state-active {
  background: #007fff !important;
}

.ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
    font-size: 12.6px;
}
</style>
